import { GET_ASSETS } from "./actionTypes";

// const BASE_URL = "https://testnets-api.opensea.io/";
const BASE_URL = "https://api.opensea.io/";

let account = "0x712E0F68350A63C21F02723b3b90E4c9dEDE569d";
const contract = "0x638181cFd1828dfa9bb407eEbDE355A01bd53B97";
// const contract = "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb";

// let account = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";

export const getAssests = () => {
  return function (dispatch) {
    return fetch(`${BASE_URL}api/v1/assets/?asset_contract_address=${contract}&order_direction=desc&offset=0&limit=50`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.assets) {
          dispatch({
            type: GET_ASSETS,
            payload: res.assets.slice(1),
          });
        }
      })
      .catch((err) => console.log(err));
  };
};

// export const getAssests = () => {
//   return function (dispatch) {
//     return fetch(`${BASE_URL}api/v1/asset_contract/${contract}`)
//       .then((res) => res.json())
//       .then((res) => {
//         console.log(res);
//         if (res.assets) {
//           dispatch({
//             type: GET_ASSETS,
//             payload: res.assets,
//           });
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };
