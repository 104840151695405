import React from "react";

function Faq() {
  return (
    <section className="light-bg padding-top-150 padding-bottom-150" id="team">
      <div className="container">
        <div className="heading text-center">
          <h2>Frequently asked questions</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nibh dolor, efficitur eget pharetra ac, cursus sed sapien. Cras posuere ligula ut blandit varius. </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="panel-group accordion" id="accordion">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    {" "}
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" className="collapsed">
                      {" "}
                      What is ICO ?
                    </a>{" "}
                  </h4>
                </div>
                <div id="collapseOne" className="panel-collapse collapse in">
                  <div className="panel-body">
                    {" "}
                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nibh dolor, efficitur eget pharetra ac,
                    cursus sed sapien. Cras posuere ligula ut blandit varius.{" "}
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    {" "}
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" className="collapsed">
                      {" "}
                      Why The Best ICO BlockChain
                    </a>{" "}
                  </h4>
                </div>
                <div id="collapseTwo" className="panel-collapse collapse">
                  <div className="panel-body"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    {" "}
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree" className="collapsed">
                      {" "}
                      What is ICO Blockchain
                    </a>{" "}
                  </h4>
                </div>
                <div id="collapseThree" className="panel-collapse collapse">
                  <div className="panel-body"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    {" "}
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapsefour" className="collapsed">
                      {" "}
                      Best Crypto in this world
                    </a>{" "}
                  </h4>
                </div>
                <div id="collapsefour" className="panel-collapse collapse">
                  <div className="panel-body"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel-group accordion" id="accordion">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    {" "}
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne-1" className="collapsed">
                      {" "}
                      Our Mission
                    </a>{" "}
                  </h4>
                </div>
                <div id="collapseOne-1" className="panel-collapse collapse">
                  <div className="panel-body"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    {" "}
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo-1" className="collapsed">
                      {" "}
                      Our Services
                    </a>{" "}
                  </h4>
                </div>
                <div id="collapseTwo-1" className="panel-collapse collapse">
                  <div className="panel-body"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    {" "}
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree-1" className="collapsed">
                      {" "}
                      Block Chain Into
                    </a>{" "}
                  </h4>
                </div>
                <div id="collapseThree-1" className="panel-collapse collapse">
                  <div className="panel-body"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    {" "}
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapsefour-1" className="collapsed">
                      {" "}
                      Crypto Currency FAQS
                    </a>{" "}
                  </h4>
                </div>
                <div id="collapsefour-1" className="panel-collapse collapse">
                  <div className="panel-body"> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
