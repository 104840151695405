import React, { useEffect, useState } from "react";
import { getAssests } from "../../redux/action";
import { connect } from "react-redux";
import placeHolder from "../../assets/placeHolder.png";
import placeHolder1 from "../../assets/placeHolder1.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";

let imgLink = [
  {
    img: "https://lh3.googleusercontent.com/-XVxq3Tro6qZLwdXTjFVEDDYIfHZ3IW78-zm8Cftqrl8Ov1jbPYn6s27fRSegG9tfzlm2eN4BkEED4pLG3GK0Ve0jsvuKdY6NGss814=w323",
    link: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/33123443712899856878205380836294455080148472267288800566807875453235207602177",
  },
  {
    img: "https://lh3.googleusercontent.com/bBpwsZ3kauupOrAGT7CT6jZ3Xe0A_ccAdosrB1fN3nrpruNdCSq_egXSMrtU6FMhqYdCmz8UJ_ybo66Is0UOHkPykr0NdCGJ5Q4giKo=w323",
    link: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/33123443712899856878205380836294455080148472267288800566807875454334719229953",
  },
  {
    img: "https://lh3.googleusercontent.com/_KwZEMP0aVpom_iAx5FGtMq6X-qPwldX8l4Khq-MOkli-Y9CVq8IAgVRPk9mU3sTTXj1_7HXNp0yHlwT-HhEW3tuQAh2fhu6ki1z_U4=w323",
    link: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/33123443712899856878205380836294455080148472267288800566807875455434230857729",
  },
  {
    img: "https://lh3.googleusercontent.com/nW8oPIvd6D4tgQvihBdGqm9KyNSNgU78zwGRJrlDe5KebSQgflZ7lG74yhyRdQGwj0vpIgsdd34ptIBWmUToLzpvs4i6ZzlxhVu1D1c=w323",
    link: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/33123443712899856878205380836294455080148472267288800566807875456533742485505",
  },
];
const useStyles = makeStyles({
  root: {
    "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "4px 14px",
    },
  },
});

function Map(props) {
  const classes = useStyles(props);
  let [area, setArea] = useState([]);
  let [img, setImg] = useState(placeHolder);
  let [grid, setGrid] = useState([]);
  const [age, setAge] = React.useState("");
  let [bigBox, setBigBox] = useState({
    305: { min: 305, max: 705, width: 5, height: 5 },
    390: { min: 390, max: 790, width: 5, height: 5 },
    2101: { min: 2101, max: 3001, width: 10, height: 10, twin: true, url: imgLink[0] },
    2111: { min: 2111, max: 3011, width: 10, height: 10 },
    2138: { min: 2138, max: 3038, width: 10, height: 10, twin: true, url: imgLink[1] },
    2148: { min: 2148, max: 3048, width: 10, height: 10 },
    2171: { min: 2171, max: 3071, width: 10, height: 10, twin: true, url: imgLink[2] },
    2181: { min: 2181, max: 3081, width: 10, height: 10 },
    4011: { min: 4011, max: 4411, width: 5, height: 5 },
    5231: { min: 5231, max: 5631, width: 5, height: 5 },
    4648: { min: 4648, max: 5548, width: 10, height: 10 },
    3969: { min: 3969, max: 4369, width: 5, height: 5 },
    6405: { min: 6405, max: 6805, width: 5, height: 5 },
    6722: { min: 6722, max: 7622, width: 10, height: 10 },
    8712: { min: 8712, max: 9112, width: 5, height: 5 },
    7438: { min: 7438, max: 8338, width: 10, height: 10, twin: true, url: imgLink[3] },
    7448: { min: 7448, max: 8348, width: 10, height: 10 },
    9644: { min: 9644, max: 10044, width: 5, height: 5 },
    6488: { min: 6488, max: 6888, width: 5, height: 5 },
    8577: { min: 8577, max: 8977, width: 5, height: 5 },
  });

  useEffect(() => {
    let grid = [];
    let row = 0,
      heighestRowRange = 100;
    let calcRealNoOfBox = 11050;
    for (let box in bigBox) {
      calcRealNoOfBox = calcRealNoOfBox - bigBox[box].width * bigBox[box].height;
    }
    // setHeighestRowRange(heighestRowRange + 100);
    let counter = 0;
    let globalBigBoxHolder = bigBox;

    for (let i = 0; i < calcRealNoOfBox; i++) {
      let bNo = counter + row * 100;
      if (globalBigBoxHolder[bNo] && bNo === globalBigBoxHolder[bNo].min) {
        let knowrow = (counter + 1 + globalBigBoxHolder[bNo].width) / heighestRowRange;
        let left = 50 * counter;
        grid.push({ uri: globalBigBoxHolder[bNo].url ? globalBigBoxHolder[bNo].url.img : placeHolder1, x: left, y: row * 50, sw: 50 * globalBigBoxHolder[bNo].width, sh: 50 * bigBox[bNo].height });
        counter += globalBigBoxHolder[bNo].width;
        let holdBigBox = { ...globalBigBoxHolder };
        delete holdBigBox[bNo];
        globalBigBoxHolder = { ...holdBigBox, [bNo + 100]: globalBigBoxHolder[bNo] };
        if (knowrow === 1) {
          // setRow(row + 1);
          row = row + 1;
          counter = 0;
        }
      } else if (globalBigBoxHolder[bNo] && bNo > globalBigBoxHolder[bNo].min) {
        let knowrow = (counter + 1 + globalBigBoxHolder[bNo].width) / heighestRowRange;
        let left = globalBigBoxHolder[bNo].twin ? 50 * (counter + globalBigBoxHolder[bNo].width * 2) : 50 * (counter + globalBigBoxHolder[bNo].width);
        grid.push({ uri: placeHolder1, x: left, y: row * 50, sw: 50, sh: 50 });
        counter = counter + globalBigBoxHolder[bNo].width;
        if (bNo < globalBigBoxHolder[bNo].max) {
          let holdBigBox = { ...globalBigBoxHolder };
          delete holdBigBox[bNo];
          globalBigBoxHolder = { ...holdBigBox, [bNo + 100]: globalBigBoxHolder[bNo] };
        }
        if (knowrow === 1) {
          // setRow(row + 1);
          row = row + 1;
          counter = 0;
        }
      } else {
        let knowrow = (counter + 1) / heighestRowRange;
        let left = 50 * counter;
        grid.push({ uri: placeHolder1, x: left, y: row * 50, sw: 50, sh: 50 });
        counter += 1;
        if (knowrow === 1) {
          // setRow(row + 1);
          row = row + 1;
          // heighestRowRange = heighestRowRange + 100;
          counter = 0;
        }
      }

      // columnCounter = columnCounter + 1;
    }
    // }
    setGrid(grid);
  }, []);

  useEffect(() => {
    props.getAssests();
  }, []);

  useEffect(() => {
    if (props.assets) {
      console.log(props.assets);
      let areaEleArr = [];
      let row = 0,
        heighestRowRange = 100,
        counter = 0;

      let calcRealNoOfBox = 11050;
      for (let box in bigBox) {
        calcRealNoOfBox = calcRealNoOfBox - bigBox[box].width * bigBox[box].height;
      }
      let globalBigBoxHolder = bigBox;
      for (let i = 0; i < calcRealNoOfBox; i++) {
        let bNo = counter + row * 100;
        if (globalBigBoxHolder[bNo] && bNo === globalBigBoxHolder[bNo].min) {
          let knowrow = (counter + 1 + globalBigBoxHolder[bNo].width) / heighestRowRange;
          let left = 10 * counter;
          // grid.push({ uri: placeHolder1, x: left, y: row * 50, sw: 50 * globalBigBoxHolder[bNo].width, sh: 50 * bigBox[bNo].height });
          areaEleArr.push(
            <area
              // className={styles.area}
              shape="rect"
              coords={`${left} ${row * 10} ${left + globalBigBoxHolder[bNo].width * 10} ${10 * (row + globalBigBoxHolder[bNo].height)}`}
              // href={bNo < props.assets.length ? `${props.assets[bNo].external_link}` : "#amresh"}
              href={globalBigBoxHolder[bNo].url ? globalBigBoxHolder[bNo].url.link : "#amresh"}
              alt="rect"
              title={bNo < props.assets.length ? props.assets[bNo].description : "amresh"}
              target="_blank"
            />
          );
          counter += globalBigBoxHolder[bNo].width;
          let holdBigBox = { ...globalBigBoxHolder };
          delete holdBigBox[bNo];
          globalBigBoxHolder = { ...holdBigBox, [bNo + 100]: globalBigBoxHolder[bNo] };
          if (knowrow === 1) {
            // setRow(row + 1);
            row = row + 1;
            counter = 0;
          }
        } else if (globalBigBoxHolder[bNo] && bNo > globalBigBoxHolder[bNo].min) {
          let knowrow = (counter + 1 + globalBigBoxHolder[bNo].width) / heighestRowRange;
          let left = 10 * (counter + globalBigBoxHolder[bNo].width);
          // grid.push({ uri: placeHolder1, x: left, y: row * 50, sw: 50, sh: 50 });
          areaEleArr.push(
            <area
              // className={styles.area}
              shape="rect"
              coords={`${left} ${row * 10} ${left + 10} ${row * 10 + 10}`}
              href={bNo < props.assets.length ? `${props.assets[bNo].external_link}` : "#"}
              alt="rect"
              title={bNo < props.assets.length ? props.assets[bNo].description : ""}
              target="_blank"
            />
          );
          counter = counter + globalBigBoxHolder[bNo].width;
          if (bNo < globalBigBoxHolder[bNo].max) {
            let holdBigBox = { ...globalBigBoxHolder };
            delete holdBigBox[bNo];
            globalBigBoxHolder = { ...holdBigBox, [bNo + 100]: globalBigBoxHolder[bNo] };
          }
          if (knowrow === 1) {
            // setRow(row + 1);
            row = row + 1;
            counter = 0;
          }
        } else {
          let knowrow = (counter + 1) / heighestRowRange;
          let left = 10 * counter;
          // grid.push({ uri: placeHolder1, x: left, y: row * 50, sw: 50, sh: 50 });
          areaEleArr.push(
            <area
              // className={styles.area}
              shape="rect"
              coords={`${left} ${row * 10} ${left + 10} ${row * 10 + 10}`}
              href={bNo < props.assets.length ? `${props.assets[bNo].external_link}` : "#"}
              alt="rect"
              title={bNo < props.assets.length ? props.assets[bNo].description : ""}
              target="_blank"
            />
          );
          counter += 1;
          if (knowrow === 1) {
            // setRow(row + 1);
            row = row + 1;
            // heighestRowRange = heighestRowRange + 100;
            counter = 0;
          }
        }
      }
      setArea(areaEleArr);
    }
  }, [props.assets]);

  function loadImages(sources, callback) {
    var images = {};
    var loadedImages = 0;
    var numImages = 0;

    // get num of sources
    for (let src of sources) {
      numImages++;
    }
    for (let i = 0; i < sources.length; i++) {
      images[`uri-${i}`] = new Image();
      images[`uri-${i}`].crossOrigin = "anonymous";
      // eslint-disable-next-line no-loop-func
      images[`uri-${i}`].onload = function () {
        if (++loadedImages >= numImages) {
          callback(images, sources[i]);
        }
      };
      images[`uri-${i}`].src = props.assets[i] ? props.assets[i].image_url : sources[i].uri;
    }
  }

  useEffect(() => {
    var canvas = document.getElementById("myCanvas");
    var context = canvas.getContext("2d");

    loadImages(grid, function (images, src) {
      grid.forEach((e, index) => context.drawImage(images[`uri-${index}`], e.x, e.y, e.sw, e.sh));

      var dataURL = canvas.toDataURL("image/png", 1.0);
      document.getElementById("imgs").src = dataURL;
    });

    // save canvas image as data url (png format by default)
    // set canvasImg image src to dataURL
    // so it can be saved as an image
  }, [props.assets]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className="container">
        {/* <Navbar /> */}

        <div className="canvasContainer">
          <canvas id="myCanvas" height="5525" width="5000" style={{ display: "none" }}></canvas>
          <img id="imgs" className="imgs" src={img} alt="imaghere" usemap="#shapemap" loading="lazy" />
          <map name="shapemap">{area}</map>
          <FormControl style={{ width: 150, marginBottom: 150, marginTop: 30 }}>
            <label className="labelSelect">City</label>
            <Select id="demo-simple-select" value={age} onChange={handleChange} style={{ padding: "4px 14px" }}>
              <MenuItem value={10}>New York City, United States</MenuItem>
              <MenuItem value={20}>Las Vegas, United States</MenuItem>
              <MenuItem value={30}>Osaka, Japan</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    assets: state.assets,
  };
};

export default connect(mapStateToProps, { getAssests })(Map);
