import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";

function Navbar() {
  const history = useHistory();
  const location = useLocation();
  const [active, setActive] = useState("home");

  useEffect(() => {
    if (location.pathname === "/") {
      setActive("home");
    } else if (location.pathname === "/whitepaper") {
      setActive("whitepaper");
    } else if (location.pathname === "/Market") {
      setActive("editspace");
    } else if (location.pathname === "/buySpace") {
      setActive("buyspace");
    }
  }, [location.pathname]);
  console.log(location);
  return (
    <header className="sticky">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img className="img-responsive" src="images/logonormalquality.png" alt="" height="20" />
          </Link>
        </div>
        <nav className="navbar ownmenu navbar-expand-lg ">
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            {" "}
            <span className="navbar-toggler-icon"></span>{" "}
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="nav">
              <li className={`scroll ${active === "home" && "active"}`}>
                <Link to="/">Home</Link>
              </li>
              <li className={`scroll ${active === "whitepaper" && "active"}`}>
                <Link to="/whitepaper">White Paper</Link>
              </li>
              {/* <li className="scroll">
                <Link to="/climate">Climate Change</Link>
              </li>
              <li className="scroll">
                <Link to="/faq">Faq</Link>
              </li> */}
              <li className={`scroll ${active === "editspace" && "active"}`}>
                <Link to="/Market">Edit Space</Link>
                {/* <a href="/Market">Edit Space</a> */}
              </li>
              <li className={`scroll ${active === "buyspace" && "active"}`}>
                <Link to="/buySpace">Buy Space</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="clearfix"></div>
    </header>
  );
}

export default Navbar;
