// import "../src/assets/main.css";
import "./App.css";
import Map from "./components/Map.js";
import { Route } from "react-router-dom";
import BuyMap from "./components/Buymap/BuyMap";
import Navbar from "./components/Navbar/Navbar";
import Faq from "./components/Faq";
import Climate from "./components/Climate";
import MarketPlace from "./components/MarketPlace/MarketPlace";
import MarketPlaceList from "./components/MarketPlace/MarketPlaceList";
import About from "./components/About";

const routes = [
  {
    path: "/",
    component: <Map />,
  },
  {
    path: "/whitepaper",
    component: <About />,
  },
  {
    path: "/faq",
    component: <Faq />,
  },
  {
    path: "/climate",
    component: <Climate />,
  },
  {
    path: "/buySpace",
    component: <BuyMap />,
  },
  {
    path: "/Market",
    component: <MarketPlaceList />,
  },
  {
    path: "/CreateItem",
    component: <MarketPlace />,
  },
];

function App() {
  return (
    <>
      <div id="loader">
        <div className="position-center-center">
          <div className="ldr"></div>
        </div>
      </div>
      <div id="wrap">
        <Navbar />
        {routes.map((e) => (
          <Route exact path={`${e.path}`}>
            {e.component}
          </Route>
        ))}
      </div>
    </>
  );
}

export default App;
