import React, { useEffect } from "react";
import { ethers } from "ethers";
import { create as ipfsHttpClient } from "ipfs-http-client";
import Web3Modal from "web3modal";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { milliondollarMarketaddress } from "../config";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import MilliondollarMarket from "../../artifacts/contracts/MillionDollarMarket.sol/MillionDollarMarket.json";

import axios from "axios";
import { useState } from "react";
import Form from "./components/Form";

const OpenSeaProfilLink = "https://testnets.opensea.io";
const client = ipfsHttpClient("https://ipfs.infura.io:5001/api/v0");

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    maxWidth: 245,
    marginRight: 15,
    marginTop: 10,
  },
  media: {
    height: 140,
  },
  productContainer: {
    padding: "15px 0px 15px 15px",
    minHeight: "600px",
  },
  Title: {
    // padding: "15px 0px 15px 15px",
  },
}));

// let opensea = "https://testnets.opensea.io/assets/0x6d68dA84382984D89212AfDA4E1FB874CB49EC36/";
// https://opensea.io/assets/matic/0x638181cfd1828dfa9bb407eebde355a01bd53b97/1
let opensea = "https://opensea.io/assets/matic/0x638181cfd1828dfa9bb407eebde355a01bd53b97/";

function MarketPlaceList() {
  const [nfts, setNfts] = useState([]);
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [currentUser, setCurrentUser] = useState("");
  // const [fileUrl, setFileUrl] = useState(null);
  const [selectedNft, setSelectedNft] = useState(null);

  useEffect(() => {
    fetchItems();
  }, []);

  const handleOpen = (nft) => {
    setSelectedNft(nft);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onChange(e) {
    const file = e.target.files[0];
    try {
      const added = await client.add(file, {
        progress: (prog) => console.log(`received: ${prog}`),
      });
      const url = `https://ipfs.infura.io/ipfs/${added.path}`;
      setFileUrl(url);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  console.log(fileUrl);

  async function createMarket() {
    /* first, upload to IPFS */
    const data = JSON.stringify({ ...selectedNft, image: fileUrl });
    console.log(data);
    try {
      const added = await client.add(data);
      const url = `https://ipfs.infura.io/ipfs/${added.path}`;
      /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
      setToken(url);
      console.log(url);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  async function setToken(url) {
    console.log("Called");
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);

    const signer = provider.getSigner();
    let contract = new ethers.Contract(milliondollarMarketaddress, MilliondollarMarket.abi, signer);

    await contract.setTokenURI(selectedNft.tokenId, url);
    // let items = await contract.idToMarketItem();

    // setLoadingState("loaded");
    fetchItems();
  }

  async function fetchItems() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);

    const signer = provider.getSigner();
    const signeraddress = await signer.getAddress();
    setCurrentUser(signeraddress);
    let contract = new ethers.Contract(milliondollarMarketaddress, MilliondollarMarket.abi, signer);

    let data = await contract.fetchAllMarketItems();
    let data1 = await contract.fetchMyItems();
    console.log(data, data1);
    // let items = await contract.idToMarketItem();
    console.log(signeraddress);
    const items = await Promise.all(
      data.map(async (i) => {
        // const tokenUri = await contract.tokenURI(i.tokenId);
        let meta;
        if (Boolean(i.tokenURI)) {
          meta = await axios.get(i.tokenURI);
        }
        // let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        let item = {
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta ? meta.data.image : "",
          name: meta ? meta.data.name : "",
          description: meta ? meta.data.description : "",
        };

        return item;
      })
    );
    setNfts(items);
    // setLoadingState("loaded");
    setOpen(false);
    console.log(data, contract);
  }

  console.log(nfts);
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
      <input type="file" name="Asset" className="my-4" onChange={onChange} />
      <button class="bg-blue-500 px-8 py-3 transition duration-300 ease-in-out hover:bg-blue-600 rounded text-white" onClick={createMarket}>
        Change Image
      </button>
    </div>
  );

  return (
    <>
      <Paper className={classes.productContainer}>
        <Typography gutterBottom variant="h5" component="h2" align="left" className={classes.Title}>
          Products
        </Typography>
        <Grid container>
          {nfts.map((e) => (
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia className={classes.media} image={e.image} title="Contemplative Reptile" />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {e.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {e.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Owned by{" "}
                    {e.owner === currentUser ? (
                      <a target="_blank" rel="noreferrer" href={`${OpenSeaProfilLink}/${e.owner}`} style={{ color: "blue" }}>
                        you
                      </a>
                    ) : (
                      <a target="_blank" rel="noreferrer" href={`${OpenSeaProfilLink}/${e.owner}`} style={{ color: "blue" }}>
                        {e.owner.slice(-6)}
                      </a>
                    )}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" onClick={() => handleOpen(e)}>
                  Edit
                </Button>
                <a target="_blank" rel="noreferrer" href={`${opensea}/${e.tokenId}`}>
                  <Button size="small" color="primary">
                    View on Opensea
                  </Button>
                </a>
                {/* <Button size="small" color="primary">
                Learn More
              </Button> */}
              </CardActions>
            </Card>
          ))}
        </Grid>
        <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
          <div style={modalStyle} className={classes.paper}>
            <Form label={"Edit Item"} createSale={setToken} selectedNft={selectedNft} />
          </div>
        </Modal>
      </Paper>
    </>
  );
}

export default MarketPlaceList;
