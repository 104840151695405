import { GET_ASSETS } from "./actionTypes";

const initialState = {
  assets: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSETS:
      return { ...state, assets: action.payload };
    default:
      return state;
  }
};

export default reducer;
