//rinkeby testnet with upgrade
// export const milliondollarMarketaddress = "0x6d68dA84382984D89212AfDA4E1FB874CB49EC36";

//rinkeby mainnet with upgrade
// export const milliondollarMarketaddress = "0x6d68dA84382984D89212AfDA4E1FB874CB49EC36";

//mumbai testnet with upgrade
// export const milliondollarMarketaddress = "0x6d68dA84382984D89212AfDA4E1FB874CB49EC36";

//mumbai mainnet with upgrade
export const milliondollarMarketaddress = "0x638181cFd1828dfa9bb407eEbDE355A01bd53B97";
