import { useState } from "react";
import { ethers } from "ethers";
import { useHistory } from "react-router-dom";
import { create as ipfsHttpClient } from "ipfs-http-client";
import Web3Modal from "web3modal";
import { milliondollarMarketaddress } from "../config";
import Form from "./components/Form";

import MilliondollarMarket from "../../artifacts/contracts/MillionDollarMarket.sol/MillionDollarMarket.json";

const client = ipfsHttpClient("https://ipfs.infura.io:5001/api/v0");
const accountArr = ["0x712E0F68350A63C21F02723b3b90E4c9dEDE569d", "0x493b346b84B3BB04Da943aA0FC8E702163999e8A"];

export default function MarketPlace() {
  const history = useHistory();

  async function createSale(url) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    // const provider = new ethers.providers.JsonRpcSigner(connection);

    const signer = provider.getSigner();
    const address = await signer.getAddress();
    if (accountArr.includes(address)) {
      console.log(address, "signer");
      let contract = new ethers.Contract(milliondollarMarketaddress, MilliondollarMarket.abi, signer);
      console.log("url", url);
      let transaction = await contract.createToken(url, contract.address, milliondollarMarketaddress);
      let tokenId = await transaction.wait();
      console.log(transaction, transaction.address, tokenId, "details");
      history.push("/Market");
    } else {
      await web3Modal.clearCachedProvider();
    }
  }

  return <Form createSale={createSale} name={"Create Digital Asset"} />;
}
