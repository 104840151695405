import { useState } from "react";
import { useHistory } from "react-router-dom";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { useEffect } from "react";

const client = ipfsHttpClient("https://ipfs.infura.io:5001/api/v0");

function Form(props) {
  const [fileUrl, setFileUrl] = useState(null);

  const [formInput, updateFormInput] = useState({ name: "", description: "", external_url: "" });
  const history = useHistory();

  async function onChange(e) {
    const file = e.target.files[0];
    try {
      const added = await client.add(file, {
        progress: (prog) => console.log(`received: ${prog}`),
      });
      const url = `https://ipfs.infura.io/ipfs/${added.path}`;
      setFileUrl(url);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  async function createMarket(e) {
    e.preventDefault();
    const { name, description, external_url } = formInput;
    if (!name || !description || !external_url || !fileUrl) return;
    /* first, upload to IPFS */
    const data = JSON.stringify({
      name,
      description,
      image: fileUrl,
      external_url,
    });
    try {
      const added = await client.add(data);
      const url = `https://ipfs.infura.io/ipfs/${added.path}`;
      /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
      props.createSale(url);
      console.log(url);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  return (
    // <div className="flex justify-center">
    //   <div className="w-1/2 flex flex-col pb-12">
    //     <input placeholder="Asset Name" className="mt-8 border rounded p-4" onChange={(e) => updateFormInput({ ...formInput, name: e.target.value })} />
    //     <input placeholder="External url" className="mt-8 border rounded p-4" onChange={(e) => updateFormInput({ ...formInput, external_url: e.target.value })} />
    //     <textarea placeholder="Asset Description" className="mt-2 border rounded p-4" onChange={(e) => updateFormInput({ ...formInput, description: e.target.value })} />
    //     {/* <input placeholder="Asset Price in Eth" className="mt-2 border rounded p-4" onChange={(e) => updateFormInput({ ...formInput, price: e.target.value })} /> */}
    //     <input type="file" name="Asset" className="my-4" onChange={onChange} />
    //     {fileUrl && <img className="rounded mt-4" width="350" src={fileUrl} alt="nft " />}
    //     <button onClick={createMarket} className="font-bold mt-4 bg-pink-500 text-white rounded p-4 shadow-lg">
    //       Create Digital Asset
    //     </button>
    //   </div>
    // </div>

    <div className="container " style={{ height: "80vh" }}>
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-8 bg-dark p-4 rounded overflow-auto h-100 mt-4">
          <form>
            <div class="form-group mb-4">
              <label for="NameNft" className="text-white pb-2">
                Asset Name
              </label>
              <input type="text" class="form-control" id="NameNft" aria-describedby="emailHelp" placeholder="Enter Name" value={formInput.name} onChange={(e) => updateFormInput({ ...formInput, name: e.target.value })} />
            </div>
            <div class="form-group mt-4">
              <label for="External URL" className="text-light pb-2">
                External url
              </label>
              <input type="text" class="form-control" id="External URL" placeholder="Enter Url" value={formInput.external_url} onChange={(e) => updateFormInput({ ...formInput, external_url: e.target.value })} />
            </div>
            <div class="form-group mt-4">
              <label class="form-check-label text-white pb-2" for="exampleCheck1">
                Enter Description
              </label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" value={formInput.description} onChange={(e) => updateFormInput({ ...formInput, description: e.target.value })}></textarea>
            </div>
            <div class="form-group mt-4 mb-2">
              <label for="exampleFormControlFile1 pb-2" className="text-white">
                Choose Image
              </label>
              <input type="file" class="form-control-file" name="Asset" id="exampleFormControlFile1" onChange={onChange} />
              {fileUrl && <img className="rounded mt-4" height="220" src={fileUrl} alt="nft " />}
            </div>
            <button onClick={createMarket} class="btn btn-primary mt-4 mb-4">
              Create Digital Asset
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Form;
