import React from "react";

function Climate() {
  return (
    <section className="why-choose padding-top-150 padding-bottom-150" id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-7 margin-top-60">
            <div className="heading margin-bottom-20">
              <h6 className="margin-bottom-10">The world’s only enterprise blockchain solution for global payments</h6>
              <h4>Best Blockchain &amp; Better Than Any Blockchain</h4>
            </div>
            <p>
              At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
              qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
            </p>
            <p>Cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
            <div className="ultra-ser">
              {" "}
              <span>
                <span className="name"> Webicode &nbsp; - &nbsp;</span> <span>Ceo/Founder Chain</span>
              </span>{" "}
            </div>
            <a className="vid-btn margin-top-30 popup-youtube" href="https://www.youtube.com/watch?v=7e90gBu4pas">
              <i className="fas fa-play-circle"></i> Watch Video <br />
              <span>How it work</span>
            </a>{" "}
          </div>

          <div className="col-md-5 text-right">
            {" "}
            <img src="images/chain-img.png" alt="Why Choose Us Imae" />{" "}
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="ib-icon">
              {" "}
              <i className="flaticon-smartphone"></i>{" "}
            </div>
            <div className="ib-info">
              <h4 className="h6">Peer-to-Peer Transactions</h4>
              <p>Contrary to popular belief , Lorem Ipsum is not simply random text. It has roots in a piece</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="ib-icon">
              {" "}
              <i className="flaticon-flat-world-map"></i>{" "}
            </div>
            <div className="ib-info">
              <h4 className="h6">Borderless Payments</h4>
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem not simply random accusantium</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="ib-icon">
              {" "}
              <i className="flaticon-secure-shield"></i>{" "}
            </div>
            <div className="ib-info">
              <h4 className="h6">Fully Protection</h4>
              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="ib-icon">
              {" "}
              <i className="flaticon-credit-card"></i>{" "}
            </div>
            <div className="ib-info">
              <h4 className="h6">Smart Money</h4>
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="ib-icon">
              {" "}
              <i className="flaticon-wallet"></i>{" "}
            </div>
            <div className="ib-info">
              <h4 className="h6">Secure Wallet</h4>
              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="ib-icon">
              {" "}
              <i className="flaticon-money"></i>{" "}
            </div>
            <div className="ib-info">
              <h4 className="h6">Easy To buy & Sell</h4>
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Climate;
