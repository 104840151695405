import React from "react";
import Particles from "./Particles/Particles";
import LanguageIcon from "@mui/icons-material/Language";
import RedditIcon from "@mui/icons-material/Reddit";

function About() {
  return (
    <>
      <section className="simple-head" data-stellar-background-ratio="0.5" id="hme">
        <Particles />
        <div className="position-center-center">
          <div className="container text-center">
            <h1>Advertisements That Fight Climate Change </h1>
            <p>NFT Advertising technology that will bring additional revenue to publishers by selling website "real estate" to advertisers. While simultaneously working hard to spread awareness about climate change.</p>
            {/* <a href="#." className="btn">
              Join Us
            </a>{" "}
            <a href="#." className="btn btn-inverse">
              View White Paper
            </a>{" "} */}
          </div>
        </div>
      </section>

      <div id="content">
        <section className="why-choose padding-top-50 padding-bottom-50" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-7 margin-top-60">
                <div className="heading margin-bottom-20">
                  <h1>Introduction</h1>
                </div>
                <p className="white-paper-subheading">We have a dream. </p>
                <p>It is a dream to solve a problem that all of us share. It is a dream to solve one of the largest existential threats out there.</p>
                <p className="white-paper-subheading">We want to solve Climate Change.</p>
                <p>So we brainstormed how to solve this problem for years. The longer we brainstormed, the harder the problem seemed to get. After all, not all of us are Elon Musk.</p>
                <p>Most billionaires still love burning oil. Most governments still love oil money.</p>
                <p className="white-paper-subheading">We are on our own to solve this problem.</p>
                <p>
                  But what if we, the citizens of the world band together and talk about climate change a lot more? Can that create an impact? An impact that might awaken the public, billionaires and governments to focus resources to
                  protect our dear earth. Maybe this might also lead to an avalanche of inspiration for a generation of people to invent companies that focus on fixing climate change.
                </p>
                <p>
                  Hence, we invented climosaurs. The dinosaurs that love to warn us about climate change! Dinosaurs died out due to a massive climate change event that occurred because of an asteroid impact, there is nobody better to warn
                  us about this huge problem.{" "}
                </p>
                <p>Nope, we are not another NFT company with funny looking Dinosaur images, although yup we will definitely have them. And yes, you can buy them too. Soon.</p>

                <p>We are much more than that. We have to think big! Climasaurs will be our brand mascot, but we are going to build something much bigger. </p>
                <p>
                  The idea came to my mind when I saw the iconic Times Square. An area laden with thousands of huge billboards. And then I imagined, what if instead of all these advertisements we had our dinosaurs warning us about climate
                  change from many billboards? That will be so cool.
                </p>

                <p>
                  But that is not how advertisements work. I am pretty sure that Samsung wont buy a huge billboard of a dinosaur for thousands of dollars. So we have to somehow combine dinosaurs and advertisements. That will bring so much
                  attention to this huge climate change problem that we all face. Thus our business idea was born.
                </p>

                <p className="font-weight-bold font-italic white-paper-subheading" style={{ fontStyle: "italic" }}>
                  “ADVERTISEMENTS THAT FIGHT CLIMATE CHANGE”
                </p>
              </div>

              <div className="col-md-5 text-right">
                {" "}
                <img src={"https://lh3.googleusercontent.com/-XVxq3Tro6qZLwdXTjFVEDDYIfHZ3IW78-zm8Cftqrl8Ov1jbPYn6s27fRSegG9tfzlm2eN4BkEED4pLG3GK0Ve0jsvuKdY6NGss814=w323"} alt="Why Choose Us Imae" />{" "}
              </div>
            </div>
          </div>
        </section>

        <section className="why-choose padding-top-50 padding-bottom-50" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 margin-top-60">
                <div className="heading margin-bottom-20">
                  <h4 className="white-paper-heading">master plan</h4>
                </div>
                <p className="text-start">
                  We have 4 steps of expansion planned for the business. Although we will need many more subsequent steps before our dream - massive awareness about the climate change problem that we all face today - becomes a reality, we
                  will start with just 4 steps. Each built in series one after another.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="distri bg-dark padding-top-50 padding-bottom-50 " id="token">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="heading margin-bottom-20">
                  <h4 className="text-white white-paper-heading">step 1: web3 times square initiation</h4>
                </div>
                <div className="ml-4" style={{ marginLeft: 50 }}>
                  <p className="text-white text-start">Since Times Square was my first inspiration for this idea, I wanted to start with this step. We are going to build the best travel destination guide on the interwebs</p>
                  <p className="text-white text-start">We will start with 3 top destinations famous for advertisements: </p>
                  <ul style={{ marginLeft: 50 }}>
                    <li class="text-white text-start">New York City, United States</li>
                    <li class="text-white text-start">Las Vegas, United States</li>
                    <li class="text-white text-start">Osaka, Japan </li>
                  </ul>
                  <p className="text-white text-start">
                    On top of the homepage with links to our extensive travel guides will be the iconic NFT advertisement grid. We will sell spots for advertisements on this grid for any person or company. Some of these spots will have our
                    friendly climosaurs - our climate change awareness dinosaurs. We will also figure out how to design the grid in a way that makes our message very clear.
                  </p>

                  <p className="text-white text-start">Now every time a human or company pays for advertisement, it will have climate awareness packed within it. How cool is that!</p>
                  <p className="text-white text-start">
                    We will also make travel destination guides for several other locations around the globe. We want this website to be the ultimate resource for travellers worldwide, and will focus a lot on SEO to generate traffic on the
                    website. As we are a bunch of veteran marketers, this is something we are extremely confident about. If needed, we will expand the NFT advertisement grids to more travel destinations in the future. We will also bring
                    grids of different shapes, locations and sizes - such as header, footer, tower .etc.
                  </p>
                  <p className="text-white text-start">
                    Once a person or company buys the NFT advertisement spot they will be able to swap their banner ads or images using our website by just paying the extremely cheap Polygon gas fee. They can also resell it to others after
                    holding it as long as they want. As our website gets more popular, so will the NFT advertisement spots - it is going to age like fine wine!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-choose padding-top-50 padding-bottom-50" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 margin-top-60">
                <div className="heading margin-bottom-20">
                  <h4 className="white-paper-heading text-start">step 2: Climate token</h4>
                </div>
                <div className="ml-4" style={{ marginLeft: 50 }}>
                  <p className="text-start">
                    CLIMATE Token will be initially a governance and reward token. More details will be given soon. Eventually, this token will metamorphose into something much more powerful, a combination of Social and Utility token that
                    will be used extensively across our family of apps. We will be creating a separate marketplace to be independent of Open Sea in the future, and this token will be used across all our services.
                  </p>
                  <p className="text-start">The details of the CLIMATE Token are still in the works and will be released soon.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="distri bg-dark padding-top-50 padding-bottom-50 " id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 margin-top-60">
                <div className="heading margin-bottom-20">
                  <h4 className="text-white white-paper-heading text-start">step 3: Climosaurs Nft</h4>
                </div>
                <div className="ml-4" style={{ marginLeft: 50 }}>
                  <p className="text-white text-start">
                    We are going to open a really fun and inspiring NFT collection of our friendly Climosaurs. We will be selling our dinosaur NFT collection in Open Sea, and will start a fun breeding/feeding game to make it more
                    interesting for collectors. Our NFTs will not just be a good investment, but also a good method to generate revenue by making use of the breeding/feeding game.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-choose padding-top-50 padding-bottom-50" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 margin-top-60">
                <div className="heading margin-bottom-20">
                  <h4 className="white-paper-heading text-start">step 4: web3 times square expansion</h4>
                </div>
                <div className="ml-4" style={{ marginLeft: 50 }}>
                  <p className="text-start">
                    We are going to expand our business by providing the advertising grid technology to more publishers across the internet. We believe that these grids will bring additional revenue to publishers, will provide good reach to
                    advertisers, and will help spread climate change awareness to website visitors. This is a total win-win-win scenario, which is unheard of in the advertisement industry. Additionally advertisers would love to display
                    their advertisements along with a positive message to save earth from climate crisis.
                  </p>
                  <p className="text-start">This is a tentative whitepaper. We will be releasing more details about our strategy, along with roadmap and deadlines in the next update.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="community-sec padding-top-150 padding-bottom-150">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="sm-intro">
                  <h2>Join our community</h2>
                  <ul className="socials">
                    <li>
                      <a href="https://www.dinostate.com">
                        {/* <i class="fab fa-globe-asia"></i> */}
                        <LanguageIcon />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.reddit.com/r/dinostate/">
                        {/* <i className="fab fa-facebook-f"></i> */}
                        <RedditIcon />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/DinoState">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/dino_state/">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="news-letter">
                  <h2>Subscribe to our newsletter</h2>
                  <form>
                    <input type="email" placeholder="Enter your email address" required />
                    <button type="submit">SEND</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
